/**
 * 获取连接中的推广信息，并将其缓存，提交时取出
 * 将地址栏中的?channel=xxx缓存
 */

const KEY_NAME = "channel";

export default function ({ app, route }) {
    if (!process.browser) {
        return;
    }

    var channel = route.query[KEY_NAME];

    if (channel) {
        sessionStorage.setItem(KEY_NAME, channel);
    }
}
