import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b62c878 = () => interopDefault(import('..\\pages\\appbh.vue' /* webpackChunkName: "pages/appbh" */))
const _1be713e3 = () => interopDefault(import('..\\pages\\error\\404.vue' /* webpackChunkName: "pages/error/404" */))
const _4cad7089 = () => interopDefault(import('..\\pages\\error\\pay-success.vue' /* webpackChunkName: "pages/error/pay-success" */))
const _03671f81 = () => interopDefault(import('..\\pages\\jiantou\\claim.vue' /* webpackChunkName: "pages/jiantou/claim" */))
const _490d27fe = () => interopDefault(import('..\\pages\\web\\bhquery\\index.vue' /* webpackChunkName: "pages/web/bhquery/index" */))
const _1429776c = () => interopDefault(import('..\\pages\\web\\ggzybh\\index.vue' /* webpackChunkName: "pages/web/ggzybh/index" */))
const _168cae16 = () => interopDefault(import('..\\pages\\web\\online-service\\index.vue' /* webpackChunkName: "pages/web/online-service/index" */))
const _37b6edd8 = () => interopDefault(import('..\\pages\\web\\register.vue' /* webpackChunkName: "pages/web/register" */))
const _22394b41 = () => interopDefault(import('..\\pages\\web\\zbdl\\index.vue' /* webpackChunkName: "pages/web/zbdl/index" */))
const _7cb70796 = () => interopDefault(import('..\\pages\\branch\\bhtong\\aboutus\\index.vue' /* webpackChunkName: "pages/branch/bhtong/aboutus/index" */))
const _63b9eb9c = () => interopDefault(import('..\\pages\\branch\\bhtong\\bh-query\\index.vue' /* webpackChunkName: "pages/branch/bhtong/bh-query/index" */))
const _255f1f47 = () => interopDefault(import('..\\pages\\branch\\bhtong\\finance\\index.vue' /* webpackChunkName: "pages/branch/bhtong/finance/index" */))
const _611b24e0 = () => interopDefault(import('..\\pages\\branch\\bhtong\\help-center\\index.vue' /* webpackChunkName: "pages/branch/bhtong/help-center/index" */))
const _5f0ba5e2 = () => interopDefault(import('..\\pages\\branch\\bhtong\\index\\index.vue' /* webpackChunkName: "pages/branch/bhtong/index/index" */))
const _a1cb367a = () => interopDefault(import('..\\pages\\branch\\bhtong\\joinpartner\\index.vue' /* webpackChunkName: "pages/branch/bhtong/joinpartner/index" */))
const _fbae6772 = () => interopDefault(import('..\\pages\\branch\\common\\aboutus\\index.vue' /* webpackChunkName: "pages/branch/common/aboutus/index" */))
const _862527a4 = () => interopDefault(import('..\\pages\\branch\\fybh\\index\\index.vue' /* webpackChunkName: "pages/branch/fybh/index/index" */))
const _8980e7e6 = () => interopDefault(import('..\\pages\\branch\\mancheng\\bh-query\\index.vue' /* webpackChunkName: "pages/branch/mancheng/bh-query/index" */))
const _17e97114 = () => interopDefault(import('..\\pages\\branch\\mancheng\\index\\index.vue' /* webpackChunkName: "pages/branch/mancheng/index/index" */))
const _6d4484f5 = () => interopDefault(import('..\\pages\\branch\\ysbh\\index\\index.vue' /* webpackChunkName: "pages/branch/ysbh/index/index" */))
const _0f22201b = () => interopDefault(import('..\\pages\\branch\\zceb\\index\\index.vue' /* webpackChunkName: "pages/branch/zceb/index/index" */))
const _61aa3e6c = () => interopDefault(import('..\\pages\\sys\\all\\all.vue' /* webpackChunkName: "pages/sys/all/all" */))
const _146c55e4 = () => interopDefault(import('..\\pages\\sys\\applybh\\paybh.vue' /* webpackChunkName: "pages/sys/applybh/paybh" */))
const _24e66cad = () => interopDefault(import('..\\pages\\sys\\applybh\\payend.vue' /* webpackChunkName: "pages/sys/applybh/payend" */))
const _5c329bce = () => interopDefault(import('..\\pages\\sys\\bhcheck\\detail.vue' /* webpackChunkName: "pages/sys/bhcheck/detail" */))
const _8a0af034 = () => interopDefault(import('..\\pages\\sys\\bhcheck\\list.vue' /* webpackChunkName: "pages/sys/bhcheck/list" */))
const _f65dcdd6 = () => interopDefault(import('..\\pages\\sys\\bhcheck\\print.vue' /* webpackChunkName: "pages/sys/bhcheck/print" */))
const _3839a29e = () => interopDefault(import('..\\pages\\sys\\coupons\\list.vue' /* webpackChunkName: "pages/sys/coupons/list" */))
const _c8129362 = () => interopDefault(import('..\\pages\\sys\\filecustomerdwmg\\list.vue' /* webpackChunkName: "pages/sys/filecustomerdwmg/list" */))
const _a46c9af6 = () => interopDefault(import('..\\pages\\sys\\ggzy\\bh-auto\\index.vue' /* webpackChunkName: "pages/sys/ggzy/bh-auto/index" */))
const _328fe14a = () => interopDefault(import('..\\pages\\sys\\mailaddressmg\\list.vue' /* webpackChunkName: "pages/sys/mailaddressmg/list" */))
const _0707eefa = () => interopDefault(import('..\\pages\\sys\\ordermg\\bh-detail.vue' /* webpackChunkName: "pages/sys/ordermg/bh-detail" */))
const _8d30fc94 = () => interopDefault(import('..\\pages\\sys\\ordermg\\list\\index.vue' /* webpackChunkName: "pages/sys/ordermg/list/index" */))
const _f74a1df0 = () => interopDefault(import('..\\pages\\sys\\ordermg\\list-usertype-1\\index.vue' /* webpackChunkName: "pages/sys/ordermg/list-usertype-1/index" */))
const _ec5b0b38 = () => interopDefault(import('..\\pages\\sys\\receiptinfomg\\list.vue' /* webpackChunkName: "pages/sys/receiptinfomg/list" */))
const _ebad71fe = () => interopDefault(import('..\\pages\\sys\\userinfo\\list.vue' /* webpackChunkName: "pages/sys/userinfo/list" */))
const _11fd2eb4 = () => interopDefault(import('..\\pages\\sys\\zb-notice\\detail.vue' /* webpackChunkName: "pages/sys/zb-notice/detail" */))
const _7c2d6f41 = () => interopDefault(import('..\\pages\\sys\\zb-notice\\list.vue' /* webpackChunkName: "pages/sys/zb-notice/list" */))
const _7adaf511 = () => interopDefault(import('..\\pages\\web\\all\\all.vue' /* webpackChunkName: "pages/web/all/all" */))
const _2fc6a220 = () => interopDefault(import('..\\pages\\web\\query\\main.vue' /* webpackChunkName: "pages/web/query/main" */))
const _7a085500 = () => interopDefault(import('..\\pages\\branch\\bhtong\\bh-query\\res.vue' /* webpackChunkName: "pages/branch/bhtong/bh-query/res" */))
const _2ec77273 = () => interopDefault(import('..\\pages\\branch\\bhtong\\news\\detail.vue' /* webpackChunkName: "pages/branch/bhtong/news/detail" */))
const _3fc44993 = () => interopDefault(import('..\\pages\\branch\\bhtong\\news\\newslist.vue' /* webpackChunkName: "pages/branch/bhtong/news/newslist" */))
const _b048586c = () => interopDefault(import('..\\pages\\sys\\ggzy\\virtual-money-acc\\detail.vue' /* webpackChunkName: "pages/sys/ggzy/virtual-money-acc/detail" */))
const _627e61d7 = () => interopDefault(import('..\\pages\\sys\\ggzy\\virtual-money-acc\\list.vue' /* webpackChunkName: "pages/sys/ggzy/virtual-money-acc/list" */))
const _21d15759 = () => interopDefault(import('..\\pages\\sys\\ggzy\\zb-file\\detail.vue' /* webpackChunkName: "pages/sys/ggzy/zb-file/detail" */))
const _186505b4 = () => interopDefault(import('..\\pages\\sys\\ggzy\\zb-file\\list.vue' /* webpackChunkName: "pages/sys/ggzy/zb-file/list" */))
const _26b821b5 = () => interopDefault(import('..\\pages\\sys\\ggzy\\zb-notice\\detail.vue' /* webpackChunkName: "pages/sys/ggzy/zb-notice/detail" */))
const _1d3bf0b0 = () => interopDefault(import('..\\pages\\sys\\ggzy\\zb-notice\\form.vue' /* webpackChunkName: "pages/sys/ggzy/zb-notice/form" */))
const _4cc113e5 = () => interopDefault(import('..\\pages\\sys\\ggzy\\zb-notice\\form-fast-add.vue' /* webpackChunkName: "pages/sys/ggzy/zb-notice/form-fast-add" */))
const _255b2e82 = () => interopDefault(import('..\\pages\\sys\\ggzy\\zb-notice\\list.vue' /* webpackChunkName: "pages/sys/ggzy/zb-notice/list" */))
const _821506a2 = () => interopDefault(import('..\\pages\\sys\\ggzy\\zb-pro\\list.vue' /* webpackChunkName: "pages/sys/ggzy/zb-pro/list" */))
const _7ceaba60 = () => interopDefault(import('..\\pages\\sys\\ggzy\\zb-pro\\list-bd.vue' /* webpackChunkName: "pages/sys/ggzy/zb-pro/list-bd" */))
const _7fb73fbb = () => interopDefault(import('..\\pages\\sys\\guarantee\\hanhua\\hhly-apply.vue' /* webpackChunkName: "pages/sys/guarantee/hanhua/hhly-apply" */))
const _c594642c = () => interopDefault(import('..\\pages\\web\\guarantee\\hanhua\\form.vue' /* webpackChunkName: "pages/web/guarantee/hanhua/form" */))
const _789b2458 = () => interopDefault(import('..\\pages\\web\\guarantee\\hanhua\\hhly-apply.vue' /* webpackChunkName: "pages/web/guarantee/hanhua/hhly-apply" */))
const _736e7fd2 = () => interopDefault(import('..\\pages\\web\\guarantee\\huanghe\\form.vue' /* webpackChunkName: "pages/web/guarantee/huanghe/form" */))
const _137298ba = () => interopDefault(import('..\\pages\\web\\guarantee\\tianan\\form.vue' /* webpackChunkName: "pages/web/guarantee/tianan/form" */))
const _9a33f990 = () => interopDefault(import('..\\pages\\web\\guarantee\\yangguang\\form.vue' /* webpackChunkName: "pages/web/guarantee/yangguang/form" */))
const _5afb608d = () => interopDefault(import('..\\pages\\web\\guarantee\\zhonghua\\form-xm.vue' /* webpackChunkName: "pages/web/guarantee/zhonghua/form-xm" */))
const _f17d59da = () => interopDefault(import('..\\pages\\branch\\bhtong\\aboutus\\sectioins\\company-introduction.vue' /* webpackChunkName: "pages/branch/bhtong/aboutus/sectioins/company-introduction" */))
const _d118a04a = () => interopDefault(import('..\\pages\\branch\\bhtong\\aboutus\\sectioins\\platform-introduction.vue' /* webpackChunkName: "pages/branch/bhtong/aboutus/sectioins/platform-introduction" */))
const _0d8dee75 = () => interopDefault(import('..\\pages\\branch\\bhtong\\aboutus\\sectioins\\statistic-box.vue' /* webpackChunkName: "pages/branch/bhtong/aboutus/sectioins/statistic-box" */))
const _967cca02 = () => interopDefault(import('..\\pages\\web\\guarantee\\tianan\\text\\text.js' /* webpackChunkName: "pages/web/guarantee/tianan/text/text" */))
const _2fe4f4ef = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _0432becb = () => interopDefault(import('..\\pages\\error\\404' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/appbh",
    component: _5b62c878,
    alias: ["/appbh/index.html","/appbh.html"],
    name: "appbh"
  }, {
    path: "/error/404",
    component: _1be713e3,
    alias: ["/error/404/index.html","/error/404.html"],
    name: "error-404"
  }, {
    path: "/error/pay-success",
    component: _4cad7089,
    alias: ["/error/pay-success/index.html","/error/pay-success.html"],
    name: "error-pay-success"
  }, {
    path: "/jiantou/claim",
    component: _03671f81,
    alias: ["/jiantou/claim/index.html","/jiantou/claim.html"],
    name: "jiantou-claim"
  }, {
    path: "/web/bhquery",
    component: _490d27fe,
    alias: ["/web/bhquery/index.html","/web/bhquery.html"],
    name: "web-bhquery"
  }, {
    path: "/web/ggzybh",
    component: _1429776c,
    alias: ["/web/ggzybh/index.html","/web/ggzybh.html"],
    name: "web-ggzybh"
  }, {
    path: "/web/online-service",
    component: _168cae16,
    alias: ["/web/online-service/index.html","/web/online-service.html"],
    name: "web-online-service"
  }, {
    path: "/web/register",
    component: _37b6edd8,
    alias: ["/web/register/index.html","/web/register.html"],
    name: "web-register"
  }, {
    path: "/web/zbdl",
    component: _22394b41,
    alias: ["/web/zbdl/index.html","/web/zbdl.html"],
    name: "web-zbdl"
  }, {
    path: "/branch/bhtong/aboutus",
    component: _7cb70796,
    alias: ["/branch/bhtong/aboutus/index.html","/branch/bhtong/aboutus.html"],
    name: "branch-bhtong-aboutus"
  }, {
    path: "/branch/bhtong/bh-query",
    component: _63b9eb9c,
    alias: ["/branch/bhtong/bh-query/index.html","/branch/bhtong/bh-query.html"],
    name: "branch-bhtong-bh-query"
  }, {
    path: "/branch/bhtong/finance",
    component: _255f1f47,
    alias: ["/branch/bhtong/finance/index.html","/branch/bhtong/finance.html"],
    name: "branch-bhtong-finance"
  }, {
    path: "/branch/bhtong/help-center",
    component: _611b24e0,
    alias: ["/branch/bhtong/help-center/index.html","/branch/bhtong/help-center.html"],
    name: "branch-bhtong-help-center"
  }, {
    path: "/branch/bhtong/index",
    component: _5f0ba5e2,
    alias: ["/branch/bhtong/index/index.html","/branch/bhtong/index.html"],
    name: "branch-bhtong-index"
  }, {
    path: "/branch/bhtong/joinpartner",
    component: _a1cb367a,
    alias: ["/branch/bhtong/joinpartner/index.html","/branch/bhtong/joinpartner.html"],
    name: "branch-bhtong-joinpartner"
  }, {
    path: "/branch/common/aboutus",
    component: _fbae6772,
    alias: ["/branch/common/aboutus/index.html","/branch/common/aboutus.html"],
    name: "branch-common-aboutus"
  }, {
    path: "/branch/fybh/index",
    component: _862527a4,
    alias: ["/branch/fybh/index/index.html","/branch/fybh/index.html"],
    name: "branch-fybh-index"
  }, {
    path: "/branch/mancheng/bh-query",
    component: _8980e7e6,
    alias: ["/branch/mancheng/bh-query/index.html","/branch/mancheng/bh-query.html"],
    name: "branch-mancheng-bh-query"
  }, {
    path: "/branch/mancheng/index",
    component: _17e97114,
    alias: ["/branch/mancheng/index/index.html","/branch/mancheng/index.html"],
    name: "branch-mancheng-index"
  }, {
    path: "/branch/ysbh/index",
    component: _6d4484f5,
    alias: ["/branch/ysbh/index/index.html","/branch/ysbh/index.html"],
    name: "branch-ysbh-index"
  }, {
    path: "/branch/zceb/index",
    component: _0f22201b,
    alias: ["/branch/zceb/index/index.html","/branch/zceb/index.html"],
    name: "branch-zceb-index"
  }, {
    path: "/sys/all/all",
    component: _61aa3e6c,
    alias: ["/sys/all/all/index.html","/sys/all/all.html"],
    name: "sys-all-all"
  }, {
    path: "/sys/applybh/paybh",
    component: _146c55e4,
    alias: ["/sys/applybh/paybh/index.html","/sys/applybh/paybh.html"],
    name: "sys-applybh-paybh"
  }, {
    path: "/sys/applybh/payend",
    component: _24e66cad,
    alias: ["/sys/applybh/payend/index.html","/sys/applybh/payend.html"],
    name: "sys-applybh-payend"
  }, {
    path: "/sys/bhcheck/detail",
    component: _5c329bce,
    alias: ["/sys/bhcheck/detail/index.html","/sys/bhcheck/detail.html"],
    name: "sys-bhcheck-detail"
  }, {
    path: "/sys/bhcheck/list",
    component: _8a0af034,
    alias: ["/sys/bhcheck/list/index.html","/sys/bhcheck/list.html"],
    name: "sys-bhcheck-list"
  }, {
    path: "/sys/bhcheck/print",
    component: _f65dcdd6,
    alias: ["/sys/bhcheck/print/index.html","/sys/bhcheck/print.html"],
    name: "sys-bhcheck-print"
  }, {
    path: "/sys/coupons/list",
    component: _3839a29e,
    alias: ["/sys/coupons/list/index.html","/sys/coupons/list.html"],
    name: "sys-coupons-list"
  }, {
    path: "/sys/filecustomerdwmg/list",
    component: _c8129362,
    alias: ["/sys/filecustomerdwmg/list/index.html","/sys/filecustomerdwmg/list.html"],
    name: "sys-filecustomerdwmg-list"
  }, {
    path: "/sys/ggzy/bh-auto",
    component: _a46c9af6,
    alias: ["/sys/ggzy/bh-auto/index.html","/sys/ggzy/bh-auto.html"],
    name: "sys-ggzy-bh-auto"
  }, {
    path: "/sys/mailaddressmg/list",
    component: _328fe14a,
    alias: ["/sys/mailaddressmg/list/index.html","/sys/mailaddressmg/list.html"],
    name: "sys-mailaddressmg-list"
  }, {
    path: "/sys/ordermg/bh-detail",
    component: _0707eefa,
    alias: ["/sys/ordermg/bh-detail/index.html","/sys/ordermg/bh-detail.html"],
    name: "sys-ordermg-bh-detail"
  }, {
    path: "/sys/ordermg/list",
    component: _8d30fc94,
    alias: ["/sys/ordermg/list/index.html","/sys/ordermg/list.html"],
    name: "sys-ordermg-list"
  }, {
    path: "/sys/ordermg/list-usertype-1",
    component: _f74a1df0,
    alias: ["/sys/ordermg/list-usertype-1/index.html","/sys/ordermg/list-usertype-1.html"],
    name: "sys-ordermg-list-usertype-1"
  }, {
    path: "/sys/receiptinfomg/list",
    component: _ec5b0b38,
    alias: ["/sys/receiptinfomg/list/index.html","/sys/receiptinfomg/list.html"],
    name: "sys-receiptinfomg-list"
  }, {
    path: "/sys/userinfo/list",
    component: _ebad71fe,
    alias: ["/sys/userinfo/list/index.html","/sys/userinfo/list.html"],
    name: "sys-userinfo-list"
  }, {
    path: "/sys/zb-notice/detail",
    component: _11fd2eb4,
    alias: ["/sys/zb-notice/detail/index.html","/sys/zb-notice/detail.html"],
    name: "sys-zb-notice-detail"
  }, {
    path: "/sys/zb-notice/list",
    component: _7c2d6f41,
    alias: ["/sys/zb-notice/list/index.html","/sys/zb-notice/list.html"],
    name: "sys-zb-notice-list"
  }, {
    path: "/web/all/all",
    component: _7adaf511,
    alias: ["/web/all/all/index.html","/web/all/all.html"],
    name: "web-all-all"
  }, {
    path: "/web/query/main",
    component: _2fc6a220,
    alias: ["/web/query/main/index.html","/web/query/main.html"],
    name: "web-query-main"
  }, {
    path: "/branch/bhtong/bh-query/res",
    component: _7a085500,
    alias: ["/branch/bhtong/bh-query/res/index.html","/branch/bhtong/bh-query/res.html"],
    name: "branch-bhtong-bh-query-res"
  }, {
    path: "/branch/bhtong/news/detail",
    component: _2ec77273,
    alias: ["/branch/bhtong/news/detail/index.html","/branch/bhtong/news/detail.html"],
    name: "branch-bhtong-news-detail"
  }, {
    path: "/branch/bhtong/news/newslist",
    component: _3fc44993,
    alias: ["/branch/bhtong/news/newslist/index.html","/branch/bhtong/news/newslist.html"],
    name: "branch-bhtong-news-newslist"
  }, {
    path: "/sys/ggzy/virtual-money-acc/detail",
    component: _b048586c,
    alias: ["/sys/ggzy/virtual-money-acc/detail/index.html","/sys/ggzy/virtual-money-acc/detail.html"],
    name: "sys-ggzy-virtual-money-acc-detail"
  }, {
    path: "/sys/ggzy/virtual-money-acc/list",
    component: _627e61d7,
    alias: ["/sys/ggzy/virtual-money-acc/list/index.html","/sys/ggzy/virtual-money-acc/list.html"],
    name: "sys-ggzy-virtual-money-acc-list"
  }, {
    path: "/sys/ggzy/zb-file/detail",
    component: _21d15759,
    alias: ["/sys/ggzy/zb-file/detail/index.html","/sys/ggzy/zb-file/detail.html"],
    name: "sys-ggzy-zb-file-detail"
  }, {
    path: "/sys/ggzy/zb-file/list",
    component: _186505b4,
    alias: ["/sys/ggzy/zb-file/list/index.html","/sys/ggzy/zb-file/list.html"],
    name: "sys-ggzy-zb-file-list"
  }, {
    path: "/sys/ggzy/zb-notice/detail",
    component: _26b821b5,
    alias: ["/sys/ggzy/zb-notice/detail/index.html","/sys/ggzy/zb-notice/detail.html"],
    name: "sys-ggzy-zb-notice-detail"
  }, {
    path: "/sys/ggzy/zb-notice/form",
    component: _1d3bf0b0,
    alias: ["/sys/ggzy/zb-notice/form/index.html","/sys/ggzy/zb-notice/form.html"],
    name: "sys-ggzy-zb-notice-form"
  }, {
    path: "/sys/ggzy/zb-notice/form-fast-add",
    component: _4cc113e5,
    alias: ["/sys/ggzy/zb-notice/form-fast-add/index.html","/sys/ggzy/zb-notice/form-fast-add.html"],
    name: "sys-ggzy-zb-notice-form-fast-add"
  }, {
    path: "/sys/ggzy/zb-notice/list",
    component: _255b2e82,
    alias: ["/sys/ggzy/zb-notice/list/index.html","/sys/ggzy/zb-notice/list.html"],
    name: "sys-ggzy-zb-notice-list"
  }, {
    path: "/sys/ggzy/zb-pro/list",
    component: _821506a2,
    alias: ["/sys/ggzy/zb-pro/list/index.html","/sys/ggzy/zb-pro/list.html"],
    name: "sys-ggzy-zb-pro-list"
  }, {
    path: "/sys/ggzy/zb-pro/list-bd",
    component: _7ceaba60,
    alias: ["/sys/ggzy/zb-pro/list-bd/index.html","/sys/ggzy/zb-pro/list-bd.html"],
    name: "sys-ggzy-zb-pro-list-bd"
  }, {
    path: "/sys/guarantee/hanhua/hhly-apply",
    component: _7fb73fbb,
    alias: ["/sys/guarantee/hanhua/hhly-apply/index.html","/sys/guarantee/hanhua/hhly-apply.html"],
    name: "sys-guarantee-hanhua-hhly-apply"
  }, {
    path: "/web/guarantee/hanhua/form",
    component: _c594642c,
    alias: ["/web/guarantee/hanhua/form/index.html","/web/guarantee/hanhua/form.html"],
    name: "web-guarantee-hanhua-form"
  }, {
    path: "/web/guarantee/hanhua/hhly-apply",
    component: _789b2458,
    alias: ["/web/guarantee/hanhua/hhly-apply/index.html","/web/guarantee/hanhua/hhly-apply.html"],
    name: "web-guarantee-hanhua-hhly-apply"
  }, {
    path: "/web/guarantee/huanghe/form",
    component: _736e7fd2,
    alias: ["/web/guarantee/huanghe/form/index.html","/web/guarantee/huanghe/form.html"],
    name: "web-guarantee-huanghe-form"
  }, {
    path: "/web/guarantee/tianan/form",
    component: _137298ba,
    alias: ["/web/guarantee/tianan/form/index.html","/web/guarantee/tianan/form.html"],
    name: "web-guarantee-tianan-form"
  }, {
    path: "/web/guarantee/yangguang/form",
    component: _9a33f990,
    alias: ["/web/guarantee/yangguang/form/index.html","/web/guarantee/yangguang/form.html"],
    name: "web-guarantee-yangguang-form"
  }, {
    path: "/web/guarantee/zhonghua/form-xm",
    component: _5afb608d,
    alias: ["/web/guarantee/zhonghua/form-xm/index.html","/web/guarantee/zhonghua/form-xm.html"],
    name: "web-guarantee-zhonghua-form-xm"
  }, {
    path: "/branch/bhtong/aboutus/sectioins/company-introduction",
    component: _f17d59da,
    alias: ["/branch/bhtong/aboutus/sectioins/company-introduction/index.html","/branch/bhtong/aboutus/sectioins/company-introduction.html"],
    name: "branch-bhtong-aboutus-sectioins-company-introduction"
  }, {
    path: "/branch/bhtong/aboutus/sectioins/platform-introduction",
    component: _d118a04a,
    alias: ["/branch/bhtong/aboutus/sectioins/platform-introduction/index.html","/branch/bhtong/aboutus/sectioins/platform-introduction.html"],
    name: "branch-bhtong-aboutus-sectioins-platform-introduction"
  }, {
    path: "/branch/bhtong/aboutus/sectioins/statistic-box",
    component: _0d8dee75,
    alias: ["/branch/bhtong/aboutus/sectioins/statistic-box/index.html","/branch/bhtong/aboutus/sectioins/statistic-box.html"],
    name: "branch-bhtong-aboutus-sectioins-statistic-box"
  }, {
    path: "/web/guarantee/tianan/text/text",
    component: _967cca02,
    alias: ["/web/guarantee/tianan/text/text/index.html","/web/guarantee/tianan/text/text.html"],
    name: "web-guarantee-tianan-text-text"
  }, {
    path: "/",
    component: _2fe4f4ef,
    alias: ["/index.html"],
    name: "index"
  }, {
    path: "*",
    component: _0432becb,
    name: "404"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
