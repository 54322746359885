export const state = () => ({
    baseUrl:'/bhtong',
})

export const mutations = {
    
}

export const getters = {

}
