import Vue from 'vue';
import storage from '@js/storage';

export const state = () => ({
    userinfo: {}
})

export const mutations = {
    userinfoUpdata(state, n) {
        if(Object.keys(n).length  === 0) {
            state.userinfo = {};
            return; 
        }
    
        var userToken = clone(n);

        userToken.expires = userToken.expires = new Date().getTime() + n.expires * 1000;

        state.userinfo = userToken;

        storage.setLocal('userInfo',userToken);
    },

}

export const getters = {
    getUserinfo(state) {
        let data = {};

        if(process.browser) {
            // data = storage.getLocal('userInfo') || state.userinfo;
            data = Object.keys(state.userinfo).length > 0 ? state.userinfo : storage.getLocal('userInfo')
        } else {
            return state.userinfo;
        }

        return data;
    },


    loginStatus(state, getters){
        let loginSatus = false;

        if(Object.keys(getters.getUserinfo).length === 0) {
            loginSatus = false;
            return loginSatus;
        }
           
        if(process.browser) {
            loginSatus = (new Date().getTime() < getters.getUserinfo.expires);
        }
        
        return loginSatus; 
    },

    uersToken(state,getters){
        let tokenStr = '';
        if(process.browser) {
            tokenStr = (Object.keys( getters.getUserinfo).length > 0 && new Date().getTime() < getters.getUserinfo.expires) ? getters.getUserinfo.token : '';
        }
        return tokenStr;
    },
};

export const actions = {
    logout({state, commit}, cb = null) {
        Vue.prototype.$post(`${this.state.api.baseUrl}/client/logout.json`, function (data) {
            commit('userinfoUpdata', {});

            if (process.browser) {
                localStorage.clear();
                // sessionStorage.clear();
            }

            cb && cb();
        });
    },
}