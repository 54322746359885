import Vue from 'vue';
import storage from '@js/storage';

export const state = () => ({
    webInfo: {}
})

export const getters = {
    webInfoData(state){
        let data = {};

        if(process.browser) {
            data = Object.keys(state.webInfo).length > 0 ? state.webInfo : storage.getSession('webInfo')
        } else {
            return state.webInfo;
        }
        
        return data;
    }

};

export const mutations = {
    updateWebInfo(state , n){
        state.webInfo = n;

        if(process.browser) {
            storage.setSession('webInfo', n);
        }
    }
}

export const actions = {
    getWebInfo(state){
        if(Object.keys(state.getters.webInfoData).length > 0) return;

        Vue.prototype.$get(`${this.state.api.baseUrl}/webinfo/detail`, data => {
            state.commit('updateWebInfo', data);
        });
    }
}

