import Vue from "vue";
import CONFIG from "../configs/index.js";

/**
 * 从后台获取配置信息
 * 并对其进行处理
 * 将其进行挂载
 * 与mixins中的page配合使用
 * @param {Object} context 插件上下文
 */

export default async function (context) {
    const { store, app, req, isDev } = context;

    var ssrHost = "";

    if (!isDev && process.server) {
        // 若为正式环境的服务端，则根据configs中设置的代理，进行预渲染端口选择
        let host = req.headers.host,
            { proxy } = CONFIG,
            filteredKeys = Object.keys(proxy).filter((key) => {
                let { reg } = proxy[key];

                return reg && reg.test(host);
            });

        if (filteredKeys[0]) {
            ssrHost = filteredKeys[0];
        }
    }

    // 空配置
    let webInfo = {
        id: '',
        webName: '',
        logoFileguid: '',
        qrcodeFileguid: '',
        webColor: '',
        email: '',
        phone1: '',
        phone2: '',
        phone3: '',
        address: '',
        onlineCode: '',
        webBqsm: '',
        webBah: '',
        webWah: '',
        aboutus: '',
        regType: [],
        regPact: '',
        webXlcp: [],
        webYqlj: [],
        webGjxz: [],
        webDhlj: [],
        webKjtd: [],
        createTime: '',
        updateTime: '',
        iconFileguid: '',
        webDir: '',
        areascope: '',
        areacode: '',
        areaname: '',
        kbareaselect: '',
        kbarea: '',
        webBqsmLink: '',
        logoUrl: '',
        qrcodeUrl: '',
        iconUrl: '',
    };

    try {
        // 从接口获取配置json
        let [data] = await app.$axios.get(`${ssrHost}${store.state.api.baseUrl}/webinfo/detail`);

        webInfo = data;
    } catch(e) {
        console.log(`获取配置json失败：`, e);
    }

    // 设置主题样式
    if (~["HomeMancheng"].indexOf(webInfo.webStyle)) {
        webInfo.defcolor = "#0154a4";

        require("@/branch/mancheng/style/element-customize.scss");
        require("@/branch/mancheng/style/common.scss");
    } else if (~["HomeFybh"].indexOf(webInfo.webStyle)) {
        webInfo.defcolor = "#38a7f5";

        require("@/branch/fybh/style/element-customize.scss");
        require("@/branch/fybh/style/common.scss");
    } else {
        webInfo.defcolor = "#ee6e26";

        require("~/assets/css/element-customize.scss");
    }

    // 设置页面head
    app.head.title = webInfo.webName;
    app.head.link.push({ rel: 'icon', type: 'image/x-icon', href: webInfo.iconUrl });

    // 存入store
    store.commit("updateWebInfo", webInfo);

    // 进行全局挂载
    Vue.prototype.webInfo = webInfo;
}
