export default{
    install: function(Vue){
        Vue.component('city-cascader', function(){return import(/*webpackChunkName: 'coms-sys'*/ './city-cascader')});
        Vue.component('my-btn', function(){return import(/*webpackChunkName: 'coms-sys'*/ './my-btn')});

        Vue.component('info-tip', function(){return import(/*webpackChunkName: 'coms-sys'*/ './info-tip')});

        Vue.component('footer-cnzz', function(){return import(/*webpackChunkName: 'coms-sys'*/ './footer-cnzz')});
        Vue.component('footer-sys', function(){return import(/*webpackChunkName: 'coms-sys'*/ './footer-sys')});

        Vue.component('header-fybh', function(){return import(/*webpackChunkName: 'coms-sys'*/ './header-fybh')});
        Vue.component('header-ggzy', function(){return import(/*webpackChunkName: 'coms-sys'*/ './header-ggzy')});
        Vue.component('header-sys', function(){return import(/*webpackChunkName: 'coms-sys'*/ './header-sys')});
    }
}
