import Vue from 'vue';
import storage from '@js/storage.js';

export default {
    state: () => ({
		state: {},
        
        bhlx: [],
        chjg: [],
        bhgs: [],
	}),
    getters: {
        
    },
    mutations: {
		updateDictionaryState(state, {name, value}) {
			state[name] = value;
			state.state[name] = true;
		},
        updateLoadingState(state, name) {
            state.state[name] = 'loading';
        },
    },
    actions: {
		// 分类
		getBhDic({state, commit}, requery) {
            if (!requery 
                && (
                    state.state.bhlx
                    && state.state.chjg
                    && state.state.bhgs
                )
            ) {
				return;
			}

            commit('updateLoadingState', 'bhlx');
            commit('updateLoadingState', 'chjg');
            commit('updateLoadingState', 'bhgs');

			
			Vue.prototype.$post(`${this.state.api.baseUrl}/commonreq/getdictinfo.json`, {
                typecode: 'bhlx',
            }, data => {
				commit('updateDictionaryState', {
					name: 'bhlx',
					value: data,
				});

                storage.setSession('bhlxList', data);
			});

            Vue.prototype.$post(`${this.state.api.baseUrl}/commonreq/getdictinfo.json`, {
                typecode: 'chjg',
            }, data => {
				commit('updateDictionaryState', {
					name: 'chjg',
					value: data,
				});

                storage.setSession('chjgList', data);
			});

            Vue.prototype.$post(`${this.state.api.baseUrl}/commonreq/getdictinfo.json`, {
                typecode: 'bhgs',
            }, data => {
				commit('updateDictionaryState', {
					name: 'bhgs',
					value: data,
				});

                storage.setSession('bhgsList', data);
			});
        },
    },
}
